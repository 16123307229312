import "./FullPage.css";
import * as React from "react";
import { Component } from "react";
import { Page, Heading } from "../../components";
import { graphql } from "gatsby";

interface RichTextContent {
  data: object;
  marks: object[];
  value: string;
  nodeType: string;
  content: RichTextContent[];
}

interface FullPageTemplateProps {
  data: {
    contentfulSitePage: {
      title: string;
      body: {
        childContentfulRichText: {
          html: string;
        };
        json: {
          content: RichTextContent[];
        };
      };
    };
  };
}

class FullPageTemplate extends Component<FullPageTemplateProps> {
  public render(): JSX.Element {
    const showContactUsTile = this.props["*"] === "frequently-asked-questions";

    const {
      html
    } = this.props.data.contentfulSitePage.body.childContentfulRichText;

    const content = html;

    return (
      <Page title={this.props.data.contentfulSitePage.title} layout="fullpage">
        <div className="fullpage-wrapper section-light-gradient">
          <div className="tile--three-fourths">
            <Heading level={1} style={{ textAlign: "center" }}>
              {this.props.data.contentfulSitePage.title}
            </Heading>
            <div className="tile fullpage-tile">
              <div
                className="fullpage--content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
        {showContactUsTile && (
          <div
            className="section-gradient tile--three-fourths"
            style={{
              display: "flex",
              height: "420px",
              paddingTop: "10px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div className="tile contact-us-tile">
              <div className="contact-us-tile__question">
                <h3>Still can&apos;t find an answer to your questions?</h3>
                <a className="link button" href="/contact-us#submit-form">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export const pageQuery = graphql`
  query FullPageBySlug($url_path: String!) {
    contentfulSitePage(url_path: { eq: $url_path }) {
      title
      body {
        childContentfulRichText {
          html
        }
        json
      }
    }
  }
`;

export default FullPageTemplate;
